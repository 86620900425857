import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { OVERLAY, GLOBAL_VARS, load_DB_vars, NAV_CONTROL } from './javascript/services/globalVars.js';
import Head from './components/header/head.js';
import LandingPage from './components/landing-page/landing';
import ServiceLauncher from './javascript/services/services';
import Overlay from './components/overlay-wrap/overlay-wrap';
import ScoringCore from './components/scoring/core/scoring-core';
import MediaPlayer from './components/media-player/media-player';
import './style/App.css';
import './style/appShare.css';
import { Sleep } from './javascript/utils/utils.js';
import LoadingBlock from './components/loading-block/loading-block.js';
import DBHandler from './javascript/index-db/db-handler';

function App() {
  const [loading, setLoading] = useState(() => true);
  const [overlayVisibility, setOverlayVisibility] = useState(0);
  const [overlayElement, setOverlayElement] = useState((<></>));
  const hideOverlay = () => { setOverlayVisibility(0); }
  const showOverlay = () => { setOverlayVisibility(1); }
  const setOverlayContent = (element) => { setOverlayElement(element); }

  OVERLAY.hide = hideOverlay;
  OVERLAY.show = showOverlay;
  OVERLAY.element = setOverlayContent;
  OVERLAY.sts = overlayVisibility;
  const commonTriggers = { overlay: OVERLAY }


  const init = async () => {
    if (window.location.pathname === '/') { setLoading(false); }
    do {
      await Sleep(100);
    } while (window.location.pathname !== '/score');
       
    // console.warn('------------------------- APP START UP -------------------------');
    const services = ServiceLauncher();
    const _result = await services.init(async () => {});

    init_variables();
  }

  const init_variables = async () => {
    if (GLOBAL_VARS.app_initiated === false) {
      // console.log('>> init_variables <<',);
      await load_DB_vars();
      // console.log('completed loading');


      try {
        const dbHandler = DBHandler();
        const account = await dbHandler.load_account();
        if (account !== false && typeof account.username === 'string') {
          document.getElementById('accountUserName-eG').innerHTML = account.username;
        } else {
          document.getElementById('accountUserName-eG').innerHTML = 'Guest';
        }
      } catch (error) {
        console.error('App - failed to load account for accountUserName-eG ', error);
      }
    }
    // setLoading(false);
  }
  

  useEffect(() => { init(); });

  useEffect(() => {
    NAV_CONTROL.appendCallback('appLoading', () => { 
      setLoading(NAV_CONTROL.state.app_loading);
    });
      return () => {
       // >>> Runs on unLoad (Unload runs first if component is already mounted)
    };
 }, []); // >> [] -- Monitor



  return (
    <div className="App">
      <BrowserRouter>
        <Head>
          <Routes></Routes>
        </Head>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/score" element={<ScoringCore commonTriggers={commonTriggers} />} />
          <Route path="*" element={<h1>Page NOT FOUND</h1>} />
        </Routes>
      </BrowserRouter>
      <MediaPlayer></MediaPlayer>
      <LoadingBlock show={loading} />
      <Overlay visibility={overlayVisibility}>{overlayElement}</Overlay>
      <span  id="accountUserName-eG" style={{ display: 'none' }}> Testing</span>
    </div>
  );
}

export default App;