// import Clock from "../clock-class/clockClass";
// import Auth from "./auth";
import DBHandler from "../index-db/db-handler";
import Sync from "./sync";
import { Sleep, genRanID } from "../utils/utils";
import { Wait_For_Auth } from "../utils/utils";
import { GLOBAL_VARS, NAV_CONTROL, SPORT_BASE, refresh_sport_base, SME_AUTH } from "./globalVars";
// const REFRESH_TIMER = 60;
// var clockClass = Clock();
// var clockObj = clockClass.Clock;

import { HotKeyController } from "../sport/class/coreSport";


var SERVICE_VAR = {
  id: null
}

const ServiceLauncher = () => {
  const init = async (loading_callback) => {
    return new Promise(async (resolve, reject) => {
      // console.log('>>> ServiceLauncher <<<');
      if (SERVICE_VAR.id !== null) {
        // console.warn('Killing Duplicate ServiceLauncher');
        return
      }

      // console.log('-----SME_AUTH------: ', SME_AUTH);

      SERVICE_VAR.id = genRanID();
      const dbHandler = DBHandler();
      dbHandler.init();
      NAV_CONTROL.setAppLoading(false);
      await SME_AUTH.init('ServiceLauncher');
      await Sleep(1000);
      await Wait_For_Auth(SME_AUTH, true);
      NAV_CONTROL.setAppLoading(true);

      SME_AUTH.is_authenticated().then(async (authResult) => {
        console.log('>>> Main authResult: ', authResult);

        if (authResult) {
          console.debug('%c [BACKEND - AUTH] >>> LOGGED IN <<<', 'background: #222; color: lightblue');
          // Start Auth Sync...
          const syncObj = new Sync(SME_AUTH, {});
          if (typeof loading_callback === 'function') { loading_callback(true); }
          await syncObj.init();


          // >>>  Refresh Sport Base
          await Sleep(250);
          refresh_sport_base();
          await Sleep(250);
          NAV_CONTROL.openMainMenuMatchHistory();
          await Sleep(250);
          NAV_CONTROL.refreshScore();
          await Sleep(250);
          NAV_CONTROL.openMainMenuNew();
          NAV_CONTROL.setAppLoading(false);
          NAV_CONTROL.refreshScore();
          await Sleep(500);

          // console.log('AAA: ', SPORT_BASE.sport_object);
          if (typeof SPORT_BASE.store_sport_config !== 'undefined' && typeof SPORT_BASE.store_sport_config.hot_keys !== 'undefined') {
            const hotKeyController = new HotKeyController({ sport_object: SPORT_BASE.sport_object });
            hotKeyController.register_hotkey_keys(SPORT_BASE.store_sport_config.hot_keys.keys);
            hotKeyController.register_hotkey_actions(SPORT_BASE.store_sport_config.hot_keys.actions);
            hotKeyController.start_listener();




          }



          if (typeof loading_callback === 'function') { loading_callback(false); }
          do {
            try {
              await syncObj.sync_watch();
            } catch (error) {
              console.error('ERROR - ServiceLauncher - init - Sync Loop: ', error);
            }

            // console.error('Sync Done Waiting....')
            // await Sleep(3000);
            await re_sync_wait(10_000);
            GLOBAL_VARS.matched_changed = Date.now();
            // console.log('>>> Waiting Done <<<');

          } while (true);
        } else {
          console.error('Un expected Error - Auth Failed, Not logged int');
          // Start Offline Sync
        }

      }).catch((e) => {
        console.error('>>>>>>> Auth Check Crashed: ', e);
        resolve(false);
      });
      resolve(true);
    });
  }



  return { init }
}



const re_sync_wait = (timeout = 5_000) => {
  return new Promise(async (resolve, reject) => {
    let timeout_id;

    timeout_id = setTimeout(() => {
      console.log('re_sync_wait timeout');
      resolve(true);
    }, timeout);

    let timestamp = Date.now();
    do {
      if (timestamp > GLOBAL_VARS.matched_changed) { await Sleep(50); }
    } while (timestamp > GLOBAL_VARS.matched_changed);

    clearTimeout(timeout_id);
    resolve(true);
  });
}




// |---------  -|-  ---------| \\

export default ServiceLauncher; 